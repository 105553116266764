import React, { Component } from 'react';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import mumbai from '../../assets/icon/mumbai-icon-removebg-preview.png';
import delhi from '../../assets/icon/delhi-icon-removebg-preview.png';
import pune from '../../assets/icon/pune-removebg-preview.png';
import banglore from '../../assets/icon/bengaluru-icon-removebg-preview.png';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export class FormUserDetails extends Component {
  continue = e => {
    e.preventDefault();
    if(this.props.values.city === ''){
      alert("Please Select your City!")
    }else{
      this.props.nextStep();
    }
  };

  handleCityChange = (event,city) => {
    this.props.handleChange('city')({ target: { value: city } });
    this.setState({ city });
  };

  render() {
    const { values } = this.props;
    return (
    <form>
        <HeaderEnquiry />
        <div className='inquiry'>
        
        <div className='glass-div'>
        <div className='glass-div-content'>
        <h1 className='title-heading'>Enter your City</h1>
            
        <ToggleButtonGroup
          className='ToggleButtonGroup'
          value={values.city}
          label="city"
          aria-label="city"
          exclusive
          color="primary"
        >
          <div className="icon-container">
          <ToggleButton className="ToggleButton" value="Bengaluru" aria-label="Bengaluru">
            <img
              className='icon-city bengaluru'
              src={banglore}
              alt='Bangalore'
              onClick={() => {
                this.handleCityChange("", "bengaluru");
                document.getElementById('bengaluru').style.backgroundColor = "#6a71f1";
                document.getElementById('delhi').style.backgroundColor = "#5a5b57";
                document.getElementById('mumbai').style.backgroundColor = "#5a5b57";
                document.getElementById('pune').style.backgroundColor = "#5a5b57";
              }}
            />
          </ToggleButton>
              <div id="bengaluru" className='image-title bengaluru'>Bengaluru</div>
           </div> 
           <div className="icon-container"> 
              <ToggleButton className="ToggleButton" value="Pune" aria-label="Pune"onClick={() => {
                  this.handleCityChange("", "pune");
                  document.getElementById('pune').style.backgroundColor = "#6a71f1";
                  document.getElementById('bengaluru').style.backgroundColor = "#5a5b57";
                  document.getElementById('mumbai').style.backgroundColor = "#5a5b57";
                  document.getElementById('delhi').style.backgroundColor = "#5a5b57";
                   }}>
                <img className='icon-city pune' src={pune} alt='pune'/>
              </ToggleButton>
              <div id="pune" className='image-title pune'>Pune</div>
            </div>
            <div className="icon-container">
              <ToggleButton className="ToggleButton" value="Delhi" aria-label="Delhi" onClick={() => {
                  this.handleCityChange("", "delhi");
                  document.getElementById('delhi').style.backgroundColor = "#6a71f1";
                  document.getElementById('bengaluru').style.backgroundColor = "#5a5b57";
                  document.getElementById('mumbai').style.backgroundColor = "#5a5b57";
                  document.getElementById('pune').style.backgroundColor = "#5a5b57";
                }}>
                <img className='icon-city' src={delhi} alt='delhi'/>
              </ToggleButton>
              <div id="delhi" className='image-title delhi'>Delhi</div>
            </div>
            <div className="icon-container">
            <ToggleButton className="ToggleButton" value="Mumbai" aria-label="Mumbai" onClick={() => {
                  this.handleCityChange("", "mumbai");
                  document.getElementById('mumbai').style.backgroundColor = "#6a71f1";
                  document.getElementById('bengaluru').style.backgroundColor = "#5a5b57";
                  document.getElementById('delhi').style.backgroundColor = "#5a5b57";
                  document.getElementById('pune').style.backgroundColor = "#5a5b57";
                }}>
              <img className='icon-city' src={mumbai} alt='mumbai'/>
            </ToggleButton>
            <div id="mumbai" className='image-title mumbai'>Mumbai</div>
            </div>
        </ToggleButtonGroup>
            <br/>
            <br/>
            <center>
                <Stack className='stacked-button' spacing={1} direction="column">
                  <Button variant="contained"  onClick={this.continue}>Continue</Button>
                </Stack>
            </center>     
            </div>
            </div>
        </div>
        <Footer/>
      </form>

    );
  }
}

export default FormUserDetails;