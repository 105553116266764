import React from 'react';
import '../chatbot/chatbot.css';

const Chatbot = () => {
  return (
    <>
    <div className="fab">
     <a href="https://wa.me/917024185979"><i class="fa fa-whatsapp" aria-hidden="true" style={{color: "white"}}></i> </a>
    </div>
    </>
  )
}

export default Chatbot