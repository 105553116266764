import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export class FormStep5 extends Component {
  continue = e => {
    e.preventDefault();
    if(this.props.values.looking_for === ''){
      alert("Please Select your Apartment Type!")
    }else{
      this.props.nextStep();
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

    // Handle city selection
    handleApartmentTypeChange = (event,looking_for) => {
      this.props.handleChange('looking_for')({ target: { value: looking_for } });
    };

  render() {
    const { values } = this.props;
    return (
      <>
      <HeaderEnquiry />
        <div className='inquiry'>
        <div className='glass-div'>
        <div className='glass-div-content'>
        <h1 className='title-heading'>What are you looking for?</h1>

        <ToggleButtonGroup
          value={values.looking_for}
          exclusive
          label="looking_for"
          defaultValue={values.looking_for}
          required
          aria-label="looking_for"
        >
          <div className="icon-container">
              <ToggleButton className="ToggleButton" value="PG" aria-label="PG" onClick={() => this.handleApartmentTypeChange("","PG")}>
                PG
              </ToggleButton>
           </div> 
           <div className="icon-container"> 
            <ToggleButton className="ToggleButton" value="Flat" aria-label="Flat" onClick={() => this.handleApartmentTypeChange("","Flat")}>
                Flat
            </ToggleButton>
          </div>
        </ToggleButtonGroup>
            <br />
            <br/>
            <center>
            <Stack className="stacked-button" spacing={2} direction="column">
              <Button variant="outlined" onClick={this.back}>Back</Button>
              <Button variant="contained" onClick={this.continue}>Continue</Button>
            </Stack>
            </center>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    );
  }
}

export default FormStep5;