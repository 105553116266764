import React from 'react';
import '../Blogs/blogs.css';

function Blog(props) {
  return (
    <div className='card-testimonial testimonial-section'>
    <h5 className='blog-desc'>{props.name}</h5>
    <img className='product--image' src={props.url} alt=''/>
    <br/><br/>
    <p>
    <button type="button" className="btn btn-primary">
      <a href={props.navigation} style={{color: "white", textDecoration: "none"}}>Read More</a>
    </button>
    </p>
  </div>
  )
}

export default Blog;
