import React, { useRef, useState, useEffect } from "react";
import { Container } from "reactstrap";
import "../Inquiry-form-header/enquiry-header.css";
import logo from '../../assets/Group 3.svg';

const navLinks = [
  {
    display: "Enquiry",
    url: "/form",
  },
  {
    display: "About Us",
    url: "/#about-us",
  },

  {
    display: "Blogs",
    url: "/#blogs",
  },
  {
    display: "Contact us",
    url: "/#contact",
  }
];

const HeaderEnquiry = () => {
  const menuRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  },[]);

  return (
    <header className={`header-inquiry ${isSticky ? "sticky" : ""}`}>
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo" >
              <a href='/' className='navbar-logo'>
              <img className="d-flex align-items-center gap-1" src={logo} alt=""></img>
              </a>
          </div>
          <div className="nav d-flex align-items-center gap-5 nav-bar-seperate">
            <div className={`nav__menu animate__animated animate__fadeInDown ${menuOpen ? "active__menu" : ""}`}
              ref={menuRef}
              onClick={menuToggle} >
              <ul className="nav__list_enquiry">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default HeaderEnquiry;