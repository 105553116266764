import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "../enquiry/enquiry.css";
import background from '../../assets/ipad mockup keyboard 2.png';
import EnquiryText from '../../assets/Frame 301.png';
import Aos from "aos";
import "aos/dist/aos.css";

const Enquiry = () => {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <section data-aos="fade-up" className="enquiry-section" id="contact">
      <Container>
        <Row>
          <Col lg="12" md="12">
            <div  className="about__content">
              <div className="about__counter d-flex justify-content-center align-items-center">
                <img className="enquiry-txt" src={EnquiryText} alt="" />
              </div>
            </div>
            <div className="image-container">
                <img className="background-img-enquiry animate__animated animate__pulse animate__infinite" src={background} alt="Background" />
            </div>
            <div className="d-flex flex-wrap">
              <Col lg="3" md="6" sm="6" xs="12">
              <a className="num" href="tel:917024185979">
                <div data-aos="fade-up" className="cards-enquiry mb-1">
                  <i className="fa fa-phone enquiry-fa" aria-hidden="true"></i>
                  <br></br>
                  +91 70241 85979
                </div>
                </a>
              </Col>  
              <Col lg="3" md="6" sm="6" xs="12">
              <a className="num" href="https://api.whatsapp.com/send?phone=917024185979">
                <div data-aos="fade-up" className="cards-enquiry mb-1">
                  <i className="fa fa-whatsapp enquiry-fa" aria-hidden="true"></i>
                  <br></br> 
                  Whatsapp
                </div>
                </a>
              </Col>

              <Col lg="3" md="6" sm="6" xs="6">
              <a className="num" href="mailto:support@staype.in">
                <div data-aos="fade-up" className="cards-enquiry mb-view mb-1">
                  <i className="fa fa-envelope enquiry-fa" aria-hidden="true"></i>
                  <br></br>
                    Email    
                </div>
                </a>
              </Col>
              <Col lg="3" md="6" sm="6" xs="6">
                <div data-aos="fade-up" className="cards-enquiry mb-view mb-1">
                <i class="fa fa-commenting enquiry-fa"></i>
                  <br></br>
                  Chat
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Enquiry;
