import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export class FormStep7 extends Component {
  continue = async (e) => {
    e.preventDefault();
    if(this.props.values.apartment_type === ''){
      alert("Please Select your Requirements!")
    }else{
    const { email, city, name, phone_number, locality, looking_for, budget, apartment_type } = this.props.values;
    let res=  await fetch("https://inquiry-form-2e38e-default-rtdb.firebaseio.com/inquiry.json", {
    method: "POST",  
    headers: {
        ContentType: "application/json"
      },
      body: JSON.stringify({
        email,
        city,
        name, 
        phone_number, 
        locality, 
        looking_for, 
        budget, 
        apartment_type
      })
  });

  if(res){
    alert("Data Stored");
    this.props.nextStep();
  }else{
    alert('Please Fill out this field!');
  } 
}
};

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  // Handle Pg selection
  handlePgTypeChange = (event,apartment_type) => {
    this.props.handleChange('apartment_type')({ target: { value: apartment_type } });
  };

  render() {
    const { values } = this.props;
    return (
      <>
      <HeaderEnquiry />
        <form>
        <div className='inquiry'>
        
        <div className='glass-div'>
        <div className='glass-div-content'>  
        <h1 className='title-heading'>Enter Your {values.looking_for} type</h1>

        <ToggleButtonGroup
          value={values.apartment_type}
          exclusive
          label="apartment_type"
          defaultValue={values.apartment_type}
          required
          aria-label="apartment_type"
        >
          <div className="icon-container">
              <ToggleButton className="ToggleButton" value="1 Sharing" aria-label="1 Sharing" onClick={() => this.handlePgTypeChange("","1 Sharing")}>
                1 Sharing
              </ToggleButton>
           </div> 
           <div className="icon-container"> 
            <ToggleButton className="ToggleButton" value="2 Sharing" aria-label="2 Sharing" onClick={() => this.handlePgTypeChange("","2 Sharing")}>
              2 sharing
            </ToggleButton>
          </div>
          <div className="icon-container"> 
            <ToggleButton className="ToggleButton" value="3 Sharing" aria-label="3 Sharing" onClick={() => this.handlePgTypeChange("","3 Sharing")}>
              3 Sharing
            </ToggleButton>
          </div>
        </ToggleButtonGroup>

            <br />
            <br />
            <center>
            <Stack className="stacked-button" spacing={2} direction="column">
              <Button variant="outlined" onClick={this.back}>Back</Button>
              <Button type='submit' variant="contained" onClick={this.continue}> Submit </Button>
            </Stack>
            </center>
            </div>
            </div>
        </div>
        <Footer/>
        </form>
        </>
    );
  }
}

export default FormStep7;