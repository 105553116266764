import React from 'react';
import '../../App.css';
import HeroSectionBlog from '../blogpages/herosectionBlog';
import Footer from '../Footer/Footer';
import '../blogpages/css/blogcss.css';
import BlogCard from '../blogpages/recommendedBlogs';
import Header from '../Header/Header';

export default function BlogPage6() {
    return (
        <div>
            <Header></Header>
            <HeroSectionBlog></HeroSectionBlog>
            <div className='blog-container-box'>
                <h2 className='blog-title'>Navigating Real Estate Amidst a Pandemic: How COVID-19 Impacted Realtors</h2>
                <p className='blog-content'>
                <h3>Introduction:</h3>
                <br/>
                    The COVID-19 pandemic had significant impacts on the real estate industry and realtors. Here are some of the key ways in which realtors were affected:
                    <br/><br/>
                    <b>1. Disrupted Transactions:</b> During the initial phases of the pandemic, many real estate transactions were delayed or canceled due to uncertainties and restrictions on movement.
                    <br/><br/>
                    <b>2. Shift to Virtual Services:</b> Realtors quickly adapted to virtual showings, online meetings, and electronic document signing to facilitate transactions while minimizing physical contact.
                    <br/><br/>
                    <b>3. Changing Demand:</b> The pandemic shifted demand patterns, with increased interest in suburban and rural properties, larger homes, and properties with home office space.
                    <br/><br/>
                    <b>4. Market Volatility:</b> Real estate markets experienced periods of volatility, impacting property prices and the willingness of buyers and sellers to transact.
                    <br/><br/>
                    <b>5. Remote Work Influence:</b> The rise of remote work led some individuals to reconsider their housing needs, potentially affecting rental and buying decisions.
                    <br/><br/>
                    <b>6. Health and Safety Protocols:</b> Realtors implemented health and safety protocols for in-person property showings, including mask mandates, social distancing, and sanitation measures.
                    <br/><br/>
                    <b>7. Economic Uncertainty:</b> Economic uncertainty influenced buyers' and sellers' decisions, affecting market dynamics.
                    <br/><br/>
                    <b>8. Government Stimulus:</b> Government stimulus programs and financial assistance measures had indirect effects on the real estate market by supporting economic stability.
                    <br/><br/>
                    <b>9. Technology Adoption:</b> Realtors accelerated their adoption of technology, such as virtual reality tours and digital marketing, to reach clients in a socially distant environment.
                    <br/><br/>
                    <b>10. Supply Chain Disruptions:</b> Delays in construction and supply chain disruptions affected new home construction and property availability.
                    <br/><br/>
                    <b>11. Rental Market Challenges:</b> Rental property owners faced challenges as some tenants struggled to pay rent due to job losses or financial hardships.
                    <br/><br/>
                    <b>12. Short-Term Rental Impact:</b> The short-term rental market, often used for vacation and business travel, saw decreased demand during travel restrictions.
                    <br/><br/>
                    <b>13. Market Recovery:</b> In some regions, the real estate market rebounded quickly after initial disruptions, with increased demand for housing.
                    <br/><br/>
                    <b>14. Interest Rate Fluctuations:</b> Changes in interest rates influenced mortgage rates and borrowing costs, impacting affordability and buyer behavior.
                    <br/><br/>
                </p>
            </div>
            <div className='question-box'>
                <h3>WAS THIS ARTICLE HELPFUL?</h3>
                <span><p>YES / NO</p></span>
            </div>
            <BlogCard></BlogCard>
            <Footer></Footer>
        </div>
    );
}