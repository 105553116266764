import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';
import LocationSelector from './LocationSelector';

export class FormStep4 extends Component {
  continue = e => {
    e.preventDefault();
    if(this.props.values.locality === ''){
      alert("Please enter your Locality!")
    }else{
      this.props.nextStep();
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  setAddress = (event) => {
    this.props.handleChange('locality')({ target: { value: event.area +" "+ event.locality } });
  };

  render() {
    const { values } = this.props;
    return (
      <form>
        <HeaderEnquiry />
        <div className='inquiry'>
          <div className='glass-div'>
            <div className='glass-div-content'>
              <h1 className='title-heading'>Enter your Locality</h1>
                <LocationSelector setAddress={this.setAddress} values={values} />
              <br />
              <br />
              <center>
                <Stack className="stacked-button" spacing={2} direction="column">
                  <Button variant="outlined" onClick={this.back}>Back</Button>
                  <Button variant="contained" onClick={this.continue}>Continue</Button>
                </Stack>
              </center>
            </div>
          </div>
        </div>
        <Footer/>
      </form>
    );
  }
}

export default FormStep4;