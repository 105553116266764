import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export class FormName extends Component {
  continue = e => {
    e.preventDefault();
    if(this.props.values.name === ''){
      alert("Please Enter Your Name!")
    }else{
      this.props.nextStep();
    }
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange } = this.props;
    return (
      <>
      <HeaderEnquiry />
        <div className='inquiry'>
        <div className='glass-div'>
        <div className='glass-div-content'>
        <h1 className='title-heading'>Enter your Name</h1>

            <input className='input-tab'
              placeholder="Enter Your Name"
              label="name"
              onChange={handleChange('name')}
              defaultValue={values.name}
              required
            />
            <br />
            <br />
            
        <div className='inquiry-btns'>
        <center>
            <Stack className="stacked-button" spacing={2} direction="column">
              <Button variant="outlined" onClick={this.back}>Back</Button>
              <Button variant="contained" onClick={this.continue}>Continue</Button>
            </Stack>
            </center>
          </div>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    );
  }
}

export default FormName;