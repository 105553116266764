import React, {useEffect} from 'react';
import Home from './page/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BlogPage1 from '../src/components/blogpages/blogpage1';
import BlogPage2 from '../src/components/blogpages/blog2';
import BlogPage3 from '../src/components/blogpages/blog3';
import BlogPage4 from '../src/components/blogpages/blog4';
import BlogPage5 from '../src/components/blogpages/blog5';
import BlogPage6 from '../src/components/blogpages/blog6';
import BlogPage7 from '../src/components/blogpages/blog7';
import InquiryForm from '../src/components/inquiryForm/form';
import ReactGA from "react-ga4";

const TRACKING_ID = "G-SRF7DNKCKV";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search , title: "Home Page" });
  }, []);
  return (
    <div className="home">
    <Router>
      <Routes>
        <Route path='/' exact Component={Home} />
        <Route path='/blogpage' exact Component={BlogPage1} />
        <Route path='/blogpage2' exact Component={BlogPage2} />
        <Route path='/blogpage3' exact Component={BlogPage3} />
        <Route path='/blogpage4' exact Component={BlogPage4} />
        <Route path='/blogpage5' exact Component={BlogPage5} />
        <Route path='/blogpage6' exact Component={BlogPage6} />
        <Route path='/blogpage7' exact Component={BlogPage7} />
        <Route path='/form' exact Component={InquiryForm} />
      </Routes>
    </Router>
  </div>
  )
}

export default App