import React from 'react';
import '../../App.css';
import HeroSectionBlog from '../blogpages/herosectionBlog';
import Footer from '../Footer/Footer';
import '../blogpages/css/blogcss.css';
import BlogCard from '../blogpages/recommendedBlogs';
import Header from '../Header/Header';

export default function BlogPage1() {
    return (
        <div>
            <Header></Header>
            <HeroSectionBlog></HeroSectionBlog>
            <div className='blog-container-box'>
                <h2 className='blog-title'>Voices of Contentment: Heartfelt Testimonies from Happy Tenants</h2>
                <p className='blog-content'>
                <h3>Introduction:</h3>
                <br/>
                The journey of a tenant is often marked by the search for the perfect place to call home. As property managers and landlords, the satisfaction of our tenants is paramount. In this blog, we present a collection of heartfelt testimonials from our tenants, showcasing their positive experiences, and shedding light on what it truly means to cultivate a harmonious tenant-landlord relationship.
                <br/><br/>
                <h3>A Sanctuary of Support: </h3>
                <br/>
                "From the moment I stepped into my new apartment, I felt a sense of belonging. The property management team has been incredibly supportive, promptly addressing any concerns. It's refreshing to live in a place where your well-being matters." - Anant 
                <br/><br/>
                <b>A Home Away from Home: </b>
                <br/><br/>
                "As a student, finding the right accommodation was a daunting task. However, my worries were quickly put to rest when I moved in. The warm atmosphere and diverse community have made this experience totally amazing." <br/><br/>
                <li>
                <b>Open Channels of Communication: </b>
                "I've rented from various landlords before, but this experience has been exceptional. The property manager's willingness to listen and their consistent efforts to maintain open lines of communication have created an atmosphere of trust and transparency." - Vedika Mehra 
                <br/><br/>
                </li>
                <li>
                <b>Every step with you: </b>
                 "With help of Shantanu house hunting was a piece of cake for me before that I was struggling loads to find an appropriate place that too in budget "- Ekta 
                </li><br/>
                <li>
                <b>Unforgettable Acts of Kindness: </b>
                "During a particularly challenging month, I received an unexpected care package from the property management. It contained essentials and a heartfelt note, reminding me that I was part of a caring community. These gestures truly make a difference." - Anand 
                </li><br/>
                <li>
                <b>Peace of Mind Guaranteed: </b>
                "The maintenance team deserves a round of applause. Not only do they respond promptly to issues, but their attention to detail ensures that our living spaces are safe and comfortable. This level of dedication is a rare find." - Vikas 
                </li><br/>
                <li>
                <b>Empathy in Action: </b>
                "Life took an unexpected turn, and I found myself in a financial tight spot. The understanding shown by the property management was incredible. They worked with me to find a solution that helped me stay in my beloved apartment during a tough time." - Amanda 
                </li>
                <br/><br/>

                <b>Efficiency Redefined: </b>
                <br/><br/>
                "I can't believe how hassle-free everything has been. From quick responses to maintenance requests, the efficiency of the property management team has set a new standard for me." - Vanya 
                <br/><br/><li>
                <b>Grateful Hearts Unite: </b>
                "I want to extend my gratitude to the entire team. Your dedication to creating a wonderful living environment has not gone unnoticed. This place has been more than just a roof over my head; it's been a source of comfort and happiness." -Jai 
                </li>
                <br/><br/><b>Conclusion: </b>
                <br/><br/>
                These heartfelt testimonies are a testament to the power of a positive tenant-landlord relationship. As property managers, our commitment to creating a nurturing and supportive living environment has resulted in stories of contentment and appreciation. Through empathy, communication, and a genuine desire to enhance lives, we've not only provided housing but also crafted a community where tenants truly thrive.
                </p>
            </div>
            <div className='question-box'>
                <h3>WAS THIS ARTICLE HELPFUL?</h3>
                <span><p>YES / NO</p></span>
            </div>
            <BlogCard></BlogCard>
            <Footer></Footer>
        </div>
    );
}