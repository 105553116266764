import React, {useEffect} from "react";
import {
  MDBContainer,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from "mdb-react-ui-kit";
import '../compare/compare.css';
import Aos from "aos";
import "aos/dist/aos.css";

export default function Compare() {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <section data-aos="fade-up">
        <div className="choose-staype">
            <h1 style={{textAlign: "center"}}><b>COMPARE</b><b style={{ color: "#6a71f1" }}> STAYPE</b><b> WITH OTHERS</b></h1>
        </div>
    <MDBContainer className="py-5 table-card">
      <MDBTable
        responsive
        className=" text-successtable-border border-light"
      >
        <MDBTableHead className="border-light">
          <tr>
            <th scope="col"></th>
            <th scope="col" style={{color: "#6a71f1"}} >
              StayPe
            </th>
            <th scope="col">
              Others
            </th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          <tr>
            <th scope="row" className="text-row">Only 1 month security deposit </th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >Weekly housekeeping</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >Fully furnished</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >Free wifi</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >Owner Interface</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >StayPe enabled Stay</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-row" >On-call stay supervisor</th>
            <td>
            <i class="fa fa-check" aria-hidden="true"></i>
            </td>
            <td>
            <i class="fa fa-times" aria-hidden="true"></i>
            </td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    </MDBContainer>
    </section>
  );
}