import React from "react";
import '../../App.css';
import '../blogpages/css/blogcss.css';
import img from'../../assets/Img2-removebg-preview.png';

function HeroSectionBlog() {
  return (
    <div className='blog-container' id="landing" style={{ backgroundImage:`url(${img})` }}>
      <div className='overlay'>
    </div>
  </div>
  );
}

export default HeroSectionBlog;