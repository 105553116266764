import React, { useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import '../Testimonials/testimonials.css';
import TestimonialsText from '../../assets/testimonials.png';
import {
  MDBCard,
  MDBCardBody,
  MDBContainer,
  MDBRow,
} from "mdb-react-ui-kit";
import user from '../../assets/user.png';
import Aos from "aos";
import "aos/dist/aos.css";

const Testimonials = () => {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <section className="testimonial-section" id="testimonial">
      <Container>
        <Row>
          <Col lg="12" md="12">
            <div data-aos="fade-up" className="about__content">
              <div className="about__counter d-flex justify-content-center align-items-center">
                <img className="testimonial-txt" src={TestimonialsText} alt="" />
              </div>
            </div>
            <MDBContainer fluid className="py-5">
              <MDBRow className="text-center">
                <Col md="4" sm="12" xs="12" className="mb-4 mb-md-0">
                  <MDBCard data-aos="fade-up">
                    <MDBCardBody className="py-4 mt-2">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={user}
                          className="rounded-circle shadow-1-strong"
                          width="100"
                          height="100"
                          alt="User"
                        />
                      </div>
                      <h5 className="font-weight-bold">Esha</h5>
                      <i class="fa fa-quote-left" aria-hidden="true" style={{ fontSize: "small" }}></i>
                      <p className="mb-2">
                      My search for a new home became a breeze with the assistance of Shantanu. Prior to having his assistance, I had a lot of trouble finding a suitable location that was also within my price range. Highly Recommended! 
                      </p>
                    </MDBCardBody>
                  </MDBCard>
                </Col>
                <Col md="4" sm="12" xs="12" className="mb-4 mb-md-0">
                  <MDBCard data-aos="fade-up">
                    <MDBCardBody className="py-4 mt-2">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={user}
                          className="rounded-circle shadow-1-strong"
                          width="100"
                          height="100"
                          alt="User"
                        />
                      </div>
                      <h5 className="font-weight-bold">Vedika</h5>
                      <i class="fa fa-quote-left" aria-hidden="true" style={{ fontSize: "small" }}></i>
                      <p className="mb-2">
                        I've rented from various landlords before, but this experience has been exceptional. The property manager's willingness to listen and their efforts to maintain open lines of communication have created an atmosphere of trust and transparency.
                      </p>
                    </MDBCardBody>
                  </MDBCard>
                </Col>
                <Col md="4" sm="12" xs="12" className="mb-4 mb-md-0">
                  <MDBCard data-aos="fade-up">
                    <MDBCardBody className="py-4 mt-2">
                      <div className="d-flex justify-content-center mb-4">
                        <img
                          src={user}
                          className="rounded-circle shadow-1-strong"
                          width="100"
                          height="100"
                          alt="User"
                        />
                      </div>
                      <h5 className="font-weight-bold">Rahul</h5>
                      <i class="fa fa-quote-left" aria-hidden="true" style={{ fontSize: "small" }}></i>
                      <p className="mb-2">
                      Discovering my dream flat on StayPe was effortless. The intuitive search, transparent listings, and exceptional customer support made it stress-free. Thanks to them, I've found my perfect home in Banglore!
                      </p>
                    </MDBCardBody>
                  </MDBCard>
                </Col>
              </MDBRow>
            </MDBContainer>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Testimonials;
