import React, {useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import "../about-us/about.css";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <section data-aos="fade-up" className="about-section" id="about-us">
      <Container>
      <Row className="row-about justify-content-center align-items-center">
        <Col lg="8" md="8" sm="12" xs="12">
          <div className="about__content col text-center">
            <div className="about__counter">
              <h1><b>ABOUT US</b></h1>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-center">
            <Col lg="12" md="12" sm="12" xs="12">
              <div className="cards-enquiry-row2 mb-3 text-center">
                <h4 className="card-title"><b>StayPe: Revolutionizing Real Estate industry by Digitizing Realtors and tenants </b></h4>
                <br></br>
                <p>StayPe is your GenZ buddy which helps in transforming the real estate industry by connecting tenants with trusted realtors and simplifying property management. Our mission is to digitalize the world of real estate, making it more accessible, efficient, and convenient for everyone involved. Whether you're a tenant seeking your ideal home or a realtor in need of seamless management, StayPe is your dedicated partner in the digital real estate era.</p>
              </div>
            </Col>
          </div>
        </Col>
      </Row>

        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
          <div  data-aos="fade-up" className="d-flex justify-content-center">
            <button type="button" className="btn btn-primary about-btn mr-2">Careers</button>
            <button type="button" className="btn btn-outline-primary about-btn">Learn More</button>
          </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
