import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap'; // Import Bootstrap components
import {
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import '../staype-work/staype.css';
import discover from '../../assets/compare/Frame 300.png';
import complete from '../../assets/compare/Frame 301.png';
import book from '../../assets/compare/Frame 302.png';
import Aos from "aos";
import "aos/dist/aos.css";

export default function Staype() {
  const [fillActive, setFillActive] = useState('tab1');

  const handleFillClick = (value: string) => {
    if (value === fillActive) {
      return;
    }

    setFillActive(value);
  };

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  return (
    <Container>
      <Row>
        <Col lg="12" md="12">
            <div data-aos="fade-up" className="about__content">
              <div className="about__counter d-flex justify-content-center align-items-center">
                <h1><b>HOW STAYPE WORKS</b></h1>
              </div>
            </div>  
          <MDBTabs fill className='mb-3'>
            <MDBTabsItem>
              <MDBTabsLink onClick={() => handleFillClick('tab1')} active={fillActive === 'tab1'}>
                <h3 style={{color: "#6a71f1"}}>For Tenants</h3>
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>

          <MDBTabsContent>
            <MDBTabsPane show={fillActive === 'tab1'}>
            <Row className="justify-content-center align-items-center">
              <Col lg="12" md="12" sm="12" xs="12">
                <div className="d-flex staype-works flex-wrap justify-content-center">
                  <Col lg="4" md="4" sm="12" xs="12">
                    <div data-aos="fade-up"  className=" mb-3 text-center">
                    <img className="tenant-img" src={discover} alt=''></img>
                    </div>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <div data-aos="fade-up" className=" mb-3 text-center">
                    <img className="tenant-img" src={complete} alt=''></img>
                    </div>
                  </Col>
                  <Col lg="4" md="4" sm="12" xs="12">
                    <div data-aos="fade-up" className=" mb-3 text-center">
                    <img className="tenant-img" src={book} alt=''></img>
                    </div>
                  </Col>
                </div>
              </Col>
            </Row>                 
            </MDBTabsPane>
            <MDBTabsPane show={fillActive === 'tab2'}>
                Tab 2 content
            </MDBTabsPane>
          </MDBTabsContent>
        </Col>
      </Row>
    </Container>
  );
}
