import React, { Fragment } from 'react';
import HeroSection from '../components/Hero-Section/HeroSection';
import ChooseUs from '../components/Choose-us/ChooseUs';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Enquiry from '../components/enquiry/Enquiry';
import About from '../components/about-us/About';
import Staype from '../components/staype-work/Staype-work';
import Testimonials from '../components/Testimonials/Testimonials';
import Compare from '../components/compare/compare';
import BlogCard from '../components/Blogs/BlogCard';
import FAQ from '../components/FAQ/FAQ';
import Team from '../components/team/Team';
import Chatbot from '../components/chatbot/chatbot';


const Home = () => {
  return (
    <Fragment>
      <Chatbot />
      <Header />
      <HeroSection />
      <ChooseUs />
      <Staype />
      <Testimonials />
      <Compare />
      <BlogCard />
      <About />
      <Team />
      <FAQ />
      <Enquiry />
      <Footer />
    </Fragment>
  )
}

export default Home