import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import logo from "../../assets/Group 3.svg";
import "./footer.css";
import fb from '../../assets/FacebookLogo.png';
import insta from '../../assets/uil_instagram-alt.png';
import x from '../../assets/fa6-brands_square-x-twitter.png';
import li from '../../assets/LinkedinLogo.png';

const footerInfoLinks = [
  {
    display: "Founders",
    url: "#team",
  },
  {
    display: "Careers",
    url: "#about-us",
  },

  {
    display: "Blogs",
    url: "#blog",
  },

  {
    display: "Testimonials",
    url: "#testimonial",
  },

  {
    display: "Contact Us",
    url: "#contact",
  }
];

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="6" xs="6" className="mb-4">
            <div className="logo">
            <a href='/' className='navbar-logo'>
              <img className="d-flex align-items-center gap-1" src={logo} alt=""></img>
              </a>
              <br></br>
              <p>First floor, 1537, <br></br>5th main road, Sector 7,<br></br> HSR Layout, Bengaluru,<br></br> Karnataka, 560102</p>
            </div>
          </Col>

          <Col lg="4" md="6" xs="6" className="mb-4">
            <h3 className="fw-bold" style={{color: "#6a71f1"}}>Pages</h3>
            <ListGroup className="link__list">
              {footerInfoLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          <Col lg="4" md="12" xs="12">
            <div className="follows">
              <span>
                {" "}
                <a href="https://www.facebook.com/people/StayPe/100095686476868/">
                  <img className="footer-icon" src={fb} alt=""></img>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.instagram.com/staype.in/?igshid=MzRlODBiNWFlZA%3D%3D">
                  <img className="footer-icon" src={insta} alt=""></img>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://twitter.com/staype_in">
                  <img className="footer-icon" src={x} alt=""></img>
                </a>
              </span>

              <span>
                {" "}
                <a href="https://www.linkedin.com/company/staype/about/">
                  <img className="footer-icon" src={li} alt=""></img>
                </a>
              </span>
              <br/><br/>
              <p className="mb-0 follow-items">Copyright © 2023 Staype. All rights reserved.</p>
              <p className="mb-0 follow-items">Privacy Policy | Terms of Service | Contact Us.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
