import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export default class FormBegins extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    return (
      <>
      <HeaderEnquiry />
        <div className='inquiry'>
        <div className='glass-div'>
            <div className='glass-div-content'>
            <h1 className='title-heading'>Looking for a perfect Stay?</h1>
            <h3 className='sub-title-heading'>Tell us your preference, and we'll help you find your ideal Stay.</h3>
            <br />
            <br />
            <center>
              <Stack className="stacked-button" spacing={1} direction="column">
              <Button variant="contained" onClick={this.continue}> Start</Button>
              </Stack>
            </center>
            </div>
        </div>
        </div>
        <Footer/>
        </>
    );
  }
}