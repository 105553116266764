import React, { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import chooseImg1 from "../../assets/why-us-1.png";
import chooseImg2 from "../../assets/why-us-2.png";
import chooseImg3 from "../../assets/why-us-3.png";
import chooseImg4 from "../../assets/Launch 1.png";
import "./choose-us.css";
import Aos from "aos";
import "aos/dist/aos.css";

const ChooseUs = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <section>
      <Container className="large-view">
        <Row>
          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__content">
              <h1>
                <b>EXPLORE DIVERSE</b>
              </h1>
              <h1 style={{ color: "#6a71f1" }}>
                <b>ACCOMMODATION</b>
              </h1>
              <h1>
                <b>OPTIONS</b>
              </h1>
              <br />
              <p>
                At Staype, we offer a diverse range of accommodation options to
                cater to your specific needs. Whether you prefer the privacy of
                your own room or the camaraderie of a shared apartment, you'll
                find the perfect match here.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__img-first">
              <img src={chooseImg1} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="large-view">
        <Row>
          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__img" style={{paddingTop : "40px"}}>
              <img src={chooseImg2} alt="" className="w-100" />
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__content">
              <br></br>
              <h1>
                <b>TAILORED</b>
              </h1>
              <h1 style={{ color: "#6a71f1" }}>
                <b>RECOMMENDATIONS</b>
              </h1>
              <br />
              <p>
                Our intelligent recommendation system uses your preferences and
                budget to suggest accommodations that match your criteria. Say
                goodbye to endless scrolling and hello to your dream space.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="large-view"> 
        <Row>
          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__content">
              <h1>
                <b>EASY</b>
                <b style={{ color: "#6a71f1" }}> BOOKING</b>
              </h1>
              <h1>
                <b>PROCESS</b>
              </h1>
              <br />
              <p>
                Booking your ideal accommodation is a breeze with Staype. Our
                user-friendly platform ensures a hassle-free experience from
                search to reservation.
              </p>
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__img">
              <img src={chooseImg3} alt="" className="w-100"  style={{paddingTop : "20px"}}/>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="large-view">
        <Row>
          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__img-last">
              <img src={chooseImg4} alt="" className="w-100" />
            </div>
          </Col>

          <Col lg="6" md="6" sm="12" xs="12">
            <div data-aos="fade-up" className="choose__content">
              <h1>
                <b>VETTED BROKERS</b>
              </h1>
              <h1>
                <b>YOU CAN</b>
                <b style={{ color: "#6a71f1" }}> TRUST</b>
              </h1>
              <br />
              <p>
                We collaborate with trusted property partners to ensure you
                have access to safe and well-maintained accommodations. Your
                comfort and security are our top priorities.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

   {/**small screens */}

<Container className="small-view">
  <Row>
    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__content">
        <h1>
          <b>EXPLORE DIVERSE</b>
        </h1>
        <h1 style={{ color: "#6a71f1" }}>
          <b>ACCOMMODATION</b>
        </h1>
        <h1>
          <b>OPTIONS</b>
        </h1>
        <br />
        <p>
          At Staype, we offer a diverse range of accommodation options to
          cater to your specific needs. Whether you prefer the privacy of
          your own room or the camaraderie of a shared apartment, you'll
          find the perfect match here.
        </p>
      </div>
    </Col>

    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__img d-flex justify-content-center"> {/* Center the image */}
        <img src={chooseImg1} alt="" className="w-100" style={{padding : "20px"}}/>
      </div>
    </Col>
  </Row>
</Container>

<Container className="small-view">
  <Row>
    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__content">
        <h1>
          <b>TAILORED</b>
        </h1>
        <h1 style={{ color: "#6a71f1" }}>
          <b>RECOMMENDATIONS</b>
        </h1>
        <br />
        <p>
          Our intelligent recommendation system uses your preferences and
          budget to suggest accommodations that match your criteria. Say
          goodbye to endless scrolling and hello to your dream space.
        </p>
      </div>
    </Col>

    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__img d-flex justify-content-center"> {/* Center the image */}
        <img src={chooseImg2} alt="" className="w-100" style={{padding : "20px"}}/>
      </div>
    </Col>
  </Row>
</Container>

<Container className="small-view">
  <Row>
    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__content">
        <h1>
          <b>EASY</b>
          <b style={{ color: "#6a71f1" }}> BOOKING</b>
        </h1>
        <h1>
          <b>PROCESS</b>
        </h1>
        <br />
        <p>
          Booking your ideal accommodation is a breeze with Staype. Our
          user-friendly platform ensures a hassle-free experience from
          search to reservation.
        </p>
      </div>
    </Col>

    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__img d-flex justify-content-center"> {/* Center the image */}
        <img src={chooseImg3} alt="" className="w-100" style={{padding : "30px 20px 20px 20px"}}/>
      </div>
    </Col>
  </Row>
</Container>

<Container className="small-view">
  <Row>

    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__content">
        <h1>
          <b>VETTED BROKERS</b>
        </h1>
        <h1>
          <b>YOU CAN</b>
          <b style={{ color: "#6a71f1" }}> TRUST</b>
        </h1>
        <br />
        <p>
          We collaborate with trusted property partners to ensure you
          have access to safe and well-maintained accommodations. Your
          comfort and security are our top priorities.
        </p>
      </div>
    </Col>

    <Col lg="6" md="6" sm="12" xs="12">
      <div data-aos="fade-up" className="choose__img-last d-flex justify-content-center"> {/* Center the image */}
        <img src={chooseImg4} alt="" className="w-100" style={{padding : "20px"}}/>
      </div>
    </Col>
    
  </Row>
</Container>



    </section>
  );
};

export default ChooseUs;
