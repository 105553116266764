import Carousel from 'react-multi-carousel';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import Blog from '../Blogs/Blogs';

export default function RecommendedBlog()
{
const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CardData = [
  {
    id:1,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'Voices of Contentment: Heartfelt Testimonies from Happy Tenants',
    pageRoute: '/blogpage'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:2,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'Navigating the Rental Market: Why Tenants Need Realtors Guide',
    pageRoute: '/blogpage2'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:3,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'Evolving Trends in the Housing Sector: The Rising Demand for Realtors',
    pageRoute: '/blogpage3'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:4,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'Finding Your Place: Common Tenant Challenges in the Housing Market',
    pageRoute: '/blogpage4'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:5,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'From Offline Visits to Clicks: The Evolution of House Rental Processes',
    pageRoute: '/blogpage5'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:6,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'Navigating Real Estate Amidst a Pandemic: How COVID-19 Impacted Realtors',
    pageRoute: '/blogpage6'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  },
  {
    id:7,
    imageurl:'https://www.jotform.com/blog/wp-content/uploads/2019/08/small-business-blogs-to-read-7063e8-2.png',
    name_obj: 'The Complex World of Realtor Perceptions: Insights and Experiences',
    pageRoute: '/blogpage7'
   // description: 'Discover how smart home technologies are transforming the real estate landscape, enhancing convenience, security, and energy efficiency for urban dwellers.'
    
  }
]
const data=  CardData.map(item => (
  <Blog name={item.name_obj} url={item.imageurl} description={item.description} navigation={item.pageRoute}/>
))

return(
  <>
  
    <div className='Blog' id='blog'>
    <h1 className='recommended-blog'>
      Recommended Blog
    </h1>
    <Carousel responsive={responsive}>
      {data}
    </Carousel>
      
</div>
</>
);
}


