import React, { useRef, useState, useEffect } from "react";
import { Container } from "reactstrap";
import "./Header.css";
import logo from '../../assets/Group 3.svg';

const navLinks = [
  {
    display: "Enquiry",
    url: "/form",
  },
  {
    display: "About Us",
    url: "#about-us",
  },
  {
    display: "Blogs",
    url: "#blogs",
  },
  {
    display: "Contact us",
    url: "#contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const menuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isSticky ? "sticky" : ""}`}>
      <Container>
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo animate__animated animate__fadeInDown">
            <a href='/' className='navbar-logo'>
              <img className="d-flex align-items-center gap-1 logo-img" src={logo} alt=""></img>
            </a>
          </div>
          <div className="nav d-flex align-items-center gap-5 nav-bar-separate">
            <div className={`nav__menu animate__animated animate__fadeInDown ${menuOpen ? "active__menu" : ""}`}
              ref={menuRef}
              onClick={menuToggle} >
              <ul className="nav__list">
                {navLinks.map((item, index) => (
                  <li key={index} className="nav__item">
                    <a href={item.url}>{item.display}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mobile__menu animate__animated animate__fadeInDown">
            <span>
              <i className="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
