import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";
import "../team/team.css";
import shantanuImg from '../../assets/shantanu.png';
import satwikImg from '../../assets/satwik-removebg-preview.png';
import shubhamImg from '../../assets/shubham_kankarwal-removebg-preview.png';
import shrutiImg from '../../assets/Shruti-removebg-preview.png';
import shivanshImg from '../../assets/Shivansh-removebg-preview.png';
import ShivImg from '../../assets/shi-removebg-preview.png';
import VishalImg from '../../assets/vishal-removebg-preview.png';
import x from '../../assets/fa6-brands_square-x-twitter.png';
import li from '../../assets/LinkedinLogo.png';
import Aos from "aos";
import "aos/dist/aos.css";

export default function Team() {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

  const isSmallScreen = useMediaQuery({ maxWidth: 576 }); // Define your breakpoint for small screens here
  const itemsPerPage = isSmallScreen ? 1 : 3;

  const [[activeIndex, direction], setActiveIndex] = useState([0, 0]);
  const items = [
    {
      image: shantanuImg,
      title: "Shantanu",
      description: "Founder and CEO",
      linkedin: "https://www.linkedin.com/in/ishantanuagrawal/",
      twitter: ""
    },
    {
      image: shrutiImg,
      title: "Shruti",
      description: "Chief Operating Officer",
      linkedin: "https://www.linkedin.com/in/shruti-singla-9870b21ab/",
      twitter: ""
    },
    {
      image: satwikImg,
      title: "Satwik",
      description: "Software Developer",
      linkedin: "https://www.linkedin.com/in/satwik-samyaan-5222651a6/",
      twitter: "https://twitter.com/SamyaanSatwik"
    },
    {
      image: shivanshImg,
      title: "Shivansh",
      description: "Data Engineer",
      linkedin: "https://www.linkedin.com/in/shivansh-jain/",
      twitter: ""
    },
    {
      image: shubhamImg,
      title: "Shubham",
      description: "Chief Business Manager",
      linkedin: "https://www.linkedin.com/in/shubham-kankarwal-67b63a246/",
      twitter: ""
    },
    {
      image: ShivImg,
      title: "Shiv Sharma",
      description: "Finance head",
      linkedin: "https://www.linkedin.com/in/",
      twitter: ""
    },
    {
      image: VishalImg,
      title: "Vishal",
      description: "UI/UX designer & consultant",
      linkedin: "https://www.linkedin.com/in/vishal-a-51bb49110/",
      twitter: ""
    },

  ];

  const indexInArrayScope =
    ((activeIndex % items.length) + items.length) % items.length;

  const visibleItems = [...items, ...items].slice(
    indexInArrayScope,
    indexInArrayScope + itemsPerPage
  );

  const handleClick = (newDirection) => {
    setActiveIndex((prevIndex) => [prevIndex[0] + newDirection, newDirection]);
  };

  return (
    <section id="team" data-aos="fade-up">
    <Container fluid className="main-wrapper">
      <Row>
        <Col lg="6" md="12" className="team-title">
          <div>
            <h1 className="meet-team">
              <b>MEET OUR</b>
            </h1>
            <h1 className="meet-team">
              <b style={{ color: "#6a71f1" }}> TEAM</b>
            </h1>
          </div>
        </Col>
        <Col lg="6" md="12">
          <div data-aos="fade-up" className="wrapper">
            <AnimatePresence mode="popLayout" initial={false}>
              {visibleItems.map((item) => {
                return (
                  <motion.div
                    className="card-team"
                    key={item.title}
                    layout
                    custom={{
                      direction,
                      position: () => {
                        if(isSmallScreen){
                          if (item === visibleItems[0]) {
                            return (isSmallScreen? "center" : "left");
                          } else if (item === visibleItems[1]) {
                            return (isSmallScreen? "left" : "center");;
                          } else {
                            return "right";
                          }
                        }
                        else{
                        if (item === visibleItems[0]) {
                          return (isSmallScreen? "center" : "left");
                        } else if (item === visibleItems[1]) {
                          return (isSmallScreen? "left" : "center");;
                        } else {
                          return "right";
                        }
                      }
                      },
                    }}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{ duration: 1 }}
                  >
                    <div className="team-details">
                      <img
                        src={item.image}
                        alt="Team Member"
                        width="50%"
                        height="auto"
                      />
                      <h4 style={{ color: "#6a71f1" }}>{item.title}</h4>
                      <p className="designation">{item.description}</p>
                      <div className="follows">
                        <span>
                          <a href={item.twitter}>
                            <img className="team-icon" src={x} alt=""></img>
                          </a>
                        </span>

                        <span>
                          <a href={item.linkedin}>
                            <img className="team-icon" src={li} alt=""></img>
                          </a>
                        </span>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
            </AnimatePresence>
          </div>
          <div className="buttons">
            <Button
              variant="light"
              className="mr-2"
              onClick={() => handleClick(-1)}
            >
              ◀︎
            </Button>
            <Button variant="light" onClick={() => handleClick(1)}>
              ▶︎
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
    </section>
  );
}

const variants = {
  enter: ({ direction }) => {
    return { scale: 0.2, x: direction < 1 ? 50 : -50, opacity: 0 };
  },
  center: ({ position, direction }) => {
    return {
      scale: position() === "center" ? 1 : 0.7,
      x: 0,
      zIndex: getZIndex({ position, direction }),
      opacity: 1,
    };
  },
  exit: ({ direction }) => {
    return { scale: 0.2, x: direction < 1 ? -50 : 50, opacity: 0 };
  },
};

function getZIndex({ position, direction }) {
  const indexes = {
    left: direction > 0 ? 2 : 1,
    center: 3,
    right: direction > 0 ? 1 : 2,
  };
  return indexes[position()];
}
