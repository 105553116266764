import React, { Component } from 'react';
import FormUserDetails from './FormUserDetails';
import Success from './Success'
import FormName from './FormName';
import FormStep3 from './FormStep3';
import FormStep4 from './FormStep4';
import FormStep5 from './FormStep5';
import FormStep6 from './FormStep6';
import FormStep7 from './FormStep7';
import FormBegins from './FormBegins';
import FormStep7Alter from './FormStep7Alter';
import FormEmail from './FormEmail';

export class InquiryForm extends Component {

    state = {
        step: 0,
        email: '',
        city: '',
        name: '',
        phone_number: '',
        locality: '',
        looking_for: '',
        budget: '15000',
        apartment_type: ''
    }

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    }

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    }    

    handleChange = input => e => {
        this.setState({[input]: e.target.value});
        console.log(e.target.value);
    }

    render() {
        const { step } = this.state;
        const { email, city, name, phone_number, locality, looking_for, budget, apartment_type } = this.state;
        const values = { email, city, name, phone_number, locality, looking_for, budget, apartment_type }

        switch(step){
            case 0:
                return (
                  <FormBegins
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );
            case 1:
                return (
                  <FormUserDetails
                    nextStep={this.nextStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );
               
                case 2:
                    return (
                      <FormStep4
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values}
                      />
                    );

              case 3:
                return (
                  <FormName
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );

              case 4:
                return (
                  <FormEmail
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );
              case 5:
                return (
                  <FormStep3
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );
              case 6:
                return (
                  <FormStep5
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange={this.handleChange}
                    values={values}
                  />
                );
                case 7:
                  return (
                    <FormStep6
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handleChange={this.handleChange}
                      values={values}
                    />
                  );                
              case 8:
                  if(values.looking_for === "Flat"){
                    return(
                      <FormStep7Alter
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handleChange={this.handleChange}
                      values={values}
                    />
                    );
                  }
                  else{ 
                  return (
                    <FormStep7
                      nextStep={this.nextStep}
                      prevStep={this.prevStep}
                      handleChange={this.handleChange}
                      values={values}
                    />
                  ); 
                  }                                                                             
              case 9:
                return <Success 
                values={values}
                  />;
              default:
                (console.log('This is a multi-step form built with React.'))
        }
    }
}

export default InquiryForm;