import React from "react";
import { Container, Row, Col } from "reactstrap";
import heroImg from "../../assets/herosection-img.png";
import "./heroSection.css";

const HeroSection = () => {
  return (
    <section id="heroSection" className="animate__animated animate__fadeInDown">
      <Container className="hero-section">
        <Row>
          <Col lg="8" md="8" sm="12" xs="12">
            <div className="hero__content">
              <h1 className="mb-1 hero__title">
                <b>FIND YOUR PERFECT STAY </b>
              </h1>
              <h1 className="mb-3 hero__title">
                <b  style={{ color: "#6a71f1" }}> WITH STAYPE</b>
              </h1>

            </div>
            <div className="search-container">
              <span className="search">
                <a href="/form" style={{textDecoration : "none"}} className="btn-style">Have Question? Enquire now!</a>
              </span>
              <span className="call-button">
                <a href="https://api.whatsapp.com/send?phone=917024185979" className="call" style={{textDecoration: "none"}}>CALL <i className="fa fa-phone"></i> </a>
              </span>
            </div>
            <div className="search-box">
              <span>
                <i className="fa fa-check-circle"></i> <b>Verified Listing</b>
              </span>
              <span>
                <i className="fa fa-check-circle"></i> <b>Price Match Guarantee</b>
              </span>
              <span>
                <i className="fa fa-check-circle"></i> <b>24x7 Assistance</b>
              </span>
            </div>
          </Col>

          <Col lg="4" md="4" sm="12" xs="12"> {/* Adjusted for responsive layout */}
            <img src={heroImg} alt="" className="w-100 hero__img" style={{ paddingTop: "32px"}} />
          </Col>
          
           {/* Additional CSS classes for responsiveness */}
           <Col lg="12" md="12">
          <div className="mb-4 card animate__animated animate__pulse animate__infinite">
            <div className="d-flex flex-wrap">
              <div className="mr-4" style={{ flexBasis: "calc(50% - 10px)" }}>
                <div className="d-flex align-items-center">
                  <i className="fa fa-bed mr-3 icon-align" aria-hidden="true"></i>
                  <div>
                    <b> 10k Flats </b>
                    <p className="description"> Pick your perfect stay from our abundant listing.</p>
                  </div>
                </div>
              </div>
              <div className="mr-4" style={{ flexBasis: "calc(50% - 10px)" }} >
                <div className="d-flex align-items-center">
                  <i className="fa fa-user icon-align-row-1" aria-hidden="true"></i>
                  <div>
                    <b> 100+ Brokers</b>
                    <p className="description"> Trusted partners for safe stay.</p>
                  </div>
                </div>
              </div>
              <div className="mr-4 " style={{ flexBasis: "calc(50% - 10px)" }}>
                <div className="d-flex align-items-center">
                  <i className="fa fa-building icon-align-new"></i>
                  <div>
                    <b> 4 Cities</b>
                    <p className="description"> Our Robust presence spans premier cities across the globe.</p>
                  </div>
                </div>
              </div>
              <div className="mr-4" style={{ flexBasis: "calc(50% - 10px)" }}>
                <div className="d-flex align-items-center">
                  <i className='fa fa-home icon-align'></i>
                  <div>
                    <b> 10k+ PGs </b>
                    <p className="description">Search. Select. Stay at StayPe with 10k+ data to choose from. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroSection;
