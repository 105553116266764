import React, { Component } from 'react';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

export class Success extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values } = this.props;
    console.log(values);
    return (
      <>
      <HeaderEnquiry /> 
        <div className='inquiry'>
        <div className='glass-div'>
          <div className='glass-div-content'>
            <h1 className='title-heading'> Thank You For Your Submission</h1>
            <p>Our Team will contact you for further assistance</p>
            <div>
              <a href='/'>
                <button type="button" className="btn btn-primary about-btn">Go to Home Page</button>
              </a>
            </div>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    );
  }
}

export default Success;