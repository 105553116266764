import React, { Component } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Footer from '../Footer/Footer';
import '../blogpages/css/glass.css';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import HeaderEnquiry from '../Inquiry-form-header/enquiry-header';

function valuetext(value) {
  return `${value}`;
}

const marks = [
  {
    value: 10000,
    label: '10k',
  },
  {
    value: 20000,
    label: '20k',
  },
  {
    value: 30000,
    label: '30k',
  },
  {
    value: 40000,
    label: '40k',
  },
  {
    value: 50000,
    label: '50k',
  },
  {
    value: 60000,
    label: '60k',
  },
  {
    value: 70000,
    label: '70k',
  },
  {
    value: 80000,
    label: '80k',
  },
  {
    value: 90000,
    label: '90k',
  },
  {
    value: 100000,
    label: '100k',
  },
];

export class FormStep6 extends Component {
  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleBudgetTypeChange = (event, newBudget) => {
    this.props.handleChange('budget')({ target: { value: newBudget } });
  };  

  render() {
    const { values } = this.props;

    return (
      <>
      <HeaderEnquiry />
        <div className='inquiry'>
        <div className='glass-div'>
        <div className='glass-div-content'>
        <h1 className='title-heading'>Enter Your Budget</h1>

        <Box sx={{ width: "auto", padding: "20px"}}>
          <Slider
              min={10000}
              step={2000}
              max={100000}
              marks={marks}
              getAriaValueText={valuetext}
              aria-label="Always visible"
              defaultValue={values.budget}
              valueLabelDisplay="on"
              onChange={(event, newValue) => this.handleBudgetTypeChange(event, newValue)}              
          />
        </Box>      
          <br />
          <br />
          <center>
            <Stack className="stacked-button" spacing={2} direction="column">
              <Button variant="outlined" onClick={this.back}>Back</Button>
              <Button variant="contained" onClick={this.continue}>Continue</Button>
            </Stack>
            </center>
            </div>
            </div>
        </div>
        <Footer/>
        </>
    );
  }
}

export default FormStep6;