import React, { useEffect, useRef } from 'react';

const apiKey = "AIzaSyBrNcGcna0TMn2uLRxhMBwxVwXUBjlZqzU";
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';


// Function to load a script asynchronously
function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    Object.assign(script, {
      type: 'text/javascript',
      async: true,
      src,
    });
    script.addEventListener('load', () => resolve(script));
    document.head.appendChild(script);
  });
}

// Function to extract address components
function extractAddress(place) {
  const address = {
    area: '',
    locality: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    plain() {
      const locality = this.locality ? this.locality + ', ' : '';
      const city = this.city ? this.city + ', ' : '';
      const zip = this.zip ? this.zip + ', ' : '';
      const state = this.state ? this.state + ', ' : '';
      return locality + city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes('sublocality_level_1')) {
      address.area = value;
    }

    if (types.includes('locality')) {
      address.locality = value;
    }

    if (types.includes('administrative_area_level_2')) {
      address.city = value;
    }

    if (types.includes('administrative_area_level_1')) {
      address.state = value;
    }

    if (types.includes('postal_code')) {
      address.zip = value;
    }

    if (types.includes('country')) {
      address.country = value;
    }
  });

  return address;
}

const LocationSelector = ({ setAddress, values }) => {
  const searchInput = useRef(null);

  useEffect(() => {
    const initMapScript = () => {
      if (window.google) {
        return Promise.resolve();
      }
      const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
      return loadAsyncScript(src);
    };

    const initAutocomplete = () => {
      if (!searchInput.current) return;

      const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
      autocomplete.setFields(['address_component', 'geometry']);
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        const extractedAddress = extractAddress(place);
        setAddress(extractedAddress);
        console.log(extractAddress);
      });
    };


    initMapScript().then(() => initAutocomplete());
  }, [setAddress]);

  return (
    <div>
      <div>
        <input 
        className='input-tab' 
        ref={searchInput} 
        type="text" 
        placeholder="Search location...."
        defaultValue={ values.locality }
        />
      </div>
    </div>
  );
}

export default LocationSelector;
