import React, {useEffect} from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import '../FAQ/faq.css';
import Aos from "aos";
import "aos/dist/aos.css";


function FAQ() {

  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  
  return (
    <section className='faq' data-aos="fade-up">
    <Container>
      <Row className='faq-end'>
        <Col md={12} className='faq-row'>
          <div className='Blog' id='blog'>
            <h1 className='h1-faq'>FAQ'S</h1>
          </div>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="0">
              <Accordion.Header> Can i visit the property before booking?</Accordion.Header>
              <Accordion.Body>
                Yes, you can easily schedule a visit, which as per availability can be assisted by one of our member , who would love to share stories and insight experiences with you while your visit.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="1">
              <Accordion.Header>How can i make the process of finding a property faster? </Accordion.Header>
              <Accordion.Body>
              To make your search easy and quick, you just need to fill up the enquiry form containing 5 one word questions.And you will get your stay in just few minutes.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="2">
              <Accordion.Header>How owners/brokers can connect with staype?</Accordion.Header>
              <Accordion.Body>
                If you want your property to be registered with us, call us on +91 90082 57375 and our team will get in touch and help you get onboard with us.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        {/* <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="3">
              <Accordion.Header>Is my personal information safe when using Staype?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="4">
              <Accordion.Header>How do I book an accommodation through Staype?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up" >
            <Accordion.Item eventKey="5">
              <Accordion.Header>What if I have questions or need assistance during my search?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up" >
            <Accordion.Item eventKey="6">
              <Accordion.Header>Do you offer any special discounts or deals?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up">
            <Accordion.Item eventKey="7">
              <Accordion.Header>How do I create an account on Staype?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        <Col md={12} className='faq-row'>
          <Accordion data-aos="fade-up" >
            <Accordion.Item eventKey="8">
              <Accordion.Header>What should I do if I encounter any issues with a property or booking?</Accordion.Header>
              <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                aliquip ex ea commodo consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col> */}
      </Row>
    </Container>
    </section>
  );
}

export default FAQ;
